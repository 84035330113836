/* eslint-disable no-plusplus */
/* eslint-disable import/no-unused-modules */
// https://gitlab.syncad.com/hive/hive-js/-/blob/master/src/utils.js

export default function validateAccountName(value) {
  let i;
  let label;
  let len;
  let suffix;

  suffix = 'Name should ';
  if (!value) {
    return `${suffix}not be empty.`;
  }
  const { length } = value;
  if (length < 3) {
    return `${suffix}be longer.`;
  }
  if (length > 16) {
    return `${suffix}be shorter.`;
  }
  if (/\./.test(value)) {
    suffix = 'Each segment should ';
  }
  const ref = value.split('.');
  for (i = 0, len = ref.length; i < len; i++) {
    label = ref[i];
    if (!/^[a-z]/.test(label)) {
      return `${suffix}start with a letter.`;
    }
    if (!/^[a-z0-9-]*$/.test(label)) {
      return `${suffix}have only letters, digits, or dashes.`;
    }
    if (/--/.test(label)) {
      return `${suffix}have only one dash in a row.`;
    }
    if (!/[a-z0-9]$/.test(label)) {
      return `${suffix}end with a letter or digit.`;
    }
    if (label.length < 3) {
      return `${suffix}be longer`;
    }
  }
  return null;
}
