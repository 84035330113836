import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import OutlinedInput from '@mui/material/OutlinedInput';
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles';
import { makeStyles, withStyles } from 'tss-react/mui';
import TextField from '@mui/material/TextField';
import Cookie from 'js-cookie';
import owasp from 'owasp-password-strength-test';
import React, { useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import isEmail from 'validator/lib/isEmail';
import { RECAPTCHA_SITE_KEY } from '../../config';
import { SIGN_UP } from '../../helpers/graphql/token';
import CustomSnackbar from '../Dashboard/Notifications/CustomSnackbar';
import PasswordPicker from './PasswordPicker';
import UsernamePicker from './UsernamePicker';
import { useMutation } from '@apollo/client';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles()(() => ({
  root: {
    color: 'white',
  },
}));

const whiteTheme = createTheme({
  palette: {
    primary: {
      main: '#fff',
    },
  },
});

const CssTextField = withStyles(TextField, {
  root: {
    '& label': {
      color: 'white',
    },
    '& label.Mui-focused': {
      color: 'white',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'white',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white',
      },
      '&:hover fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white',
      },
    },
  },
});
const CssInput = withStyles(OutlinedInput, {
  root: {
    '& label': {
      color: 'white',
    },
    '& label.Mui-focused': {
      color: 'white',
    },
    '& MuiFormLabel-root': {
      color: 'white',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'white',
    },
    '& fieldset': {
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
});

const OnboardStart = props => {
  const { classes } = useStyles();

  const [referrer, setReferrer] = useState(undefined);
  const [email, setEmail] = useState(undefined);
  const [newsletter, setNewsletter] = useState(false);
  const [tos, setTos] = useState(false);
  const [isMailValid, setMailValid] = useState(true);
  const [captcha, setCaptcha] = useState(undefined);
  const [username, setUsername] = useState(props.usernameToCreate);
  const [password, setPassword] = useState(undefined);
  const [isUsernameValid, setUsernameValid] = useState(undefined);
  const [success, setSuccess] = useState(undefined);
  const [showValidation, setShowValidation] = useState(false);
  const [signUpRunning, setSignUpRunning] = useState(false);

  useEffect(() => {
    if (props.referrer) {
      setReferrer(props.referrer);
    } else {
      setReferrer(Cookie.get('referrer'));
    }
  }, [props]);

  const handleEmailChange = event => {
    setEmail(event.target.value);
    if (event.target.value) setMailValid(isEmail(event.target.value));
  };

  const pwstrength = password ? owasp.test(password) : {};

  const [signUp] = useMutation(SIGN_UP);

  const handleSignUp = () => {
    if (
      !captcha ||
      !email ||
      !isMailValid ||
      !tos ||
      (pwstrength.errors && pwstrength.errors.length > 0) ||
      !isUsernameValid === true
    )
      setShowValidation(true);
    else {
      setSignUpRunning(true);
      signUp({
        variables: {
          signUp: {
            email,
            password,
            username,
            referrer,
            isNewsletter: newsletter,
            captcha,
            isTf: true,
          },
        },
      })
        .then(data => {
          if (data && data.data && data.data.signUp && data.data.signUp.success)
            setSuccess(true);
          else setSuccess(false);
        })
        .catch(err => {
          console.error(err);
          setSuccess(false);
        });
    }
  };

  if (success === true)
    return (
      <div className="pt-2">
        <CustomSnackbar
          variant="success"
          message="Welcome to the TravelFeed crew! We've sent you an email with instructions to activate your account and start exploring with us."
        />
      </div>
    );
  if (success === false)
    return (
      <div className="pt-2">
        <CustomSnackbar
          variant="error"
          message={
            <div>
              Something went wrong. Reload the page to try again. If it still
              doesn&apos;t work, please{' '}
              <a href="/about/contact" target="_blank" rel="noreferrer">
                <u>contact us</u>
              </a>
            </div>
          }
        />
      </div>
    );

  const validMailError = showValidation && (!isMailValid || !email);
  const isPwError =
    showValidation &&
    ((pwstrength.errors && pwstrength.errors.length > 0) || !password);
  const isUsernameError = showValidation && (!isUsernameValid || !username);
  const isCaptchaError = showValidation && !captcha;
  const isTosError = showValidation && !tos;

  const PasswordPickerComp = (
    <PasswordPicker
      isWhiteBg={props.isWhiteBg}
      isLightGrayBg={props.isLightGrayBg}
      pwstrength={pwstrength}
      password={password}
      setPassword={setPassword}
      label="Password"
      isValid={!isPwError}
      helper={
        isPwError
          ? pwstrength && pwstrength.errors && pwstrength.errors.length > 0
            ? pwstrength.errors[0]
            : 'Please enter a password'
          : undefined
      }
      customInput={props.isWhite ? CssInput : OutlinedInput}
      isOutlined
    />
  );

  return (
    <>
      <FormGroup>
        <FormControl variant="standard" required error={validMailError}>
          {props.isWhite ? (
            <CssTextField
              InputProps={{
                classes: {
                  input: classes.root,
                },
              }}
              autoFocus={props.autoFocus}
              className={classes.margin}
              label="Email"
              type="email"
              name="email"
              autoComplete="email"
              margin="normal"
              variant="outlined"
              value={email}
              onChange={handleEmailChange}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              inputProps={{
                maxLength: 100,
              }}
              error={validMailError}
            />
          ) : (
            <TextField
              autoFocus={props.autoFocus}
              label="Email"
              type="email"
              name="email"
              autoComplete="email"
              margin="normal"
              variant="outlined"
              value={email}
              error={validMailError}
              onChange={handleEmailChange}
              inputProps={{
                maxLength: 100,
              }}
            />
          )}
          {validMailError && (
            <FormHelperText>A valid email is required</FormHelperText>
          )}
        </FormControl>
        <div className="pt-2">
          <StyledEngineProvider injectFirst>
            {props.isWhite ? (
              <ThemeProvider theme={props.isWhite ? whiteTheme : undefined}>
                {PasswordPickerComp}
              </ThemeProvider>
            ) : (
              PasswordPickerComp
            )}
          </StyledEngineProvider>
        </div>
        <UsernamePicker
          data={username}
          placeholder="Pick a username"
          onChange={res => setUsername(res)}
          isValid={isUsernameValid}
          isError={isUsernameError}
          setValid={setUsernameValid}
          textFieldVariant="outlined"
          customTextField={props.isWhite ? CssTextField : undefined}
        />{' '}
        <FormControl variant="standard" required error={isTosError}>
          <FormControlLabel
            error={isTosError}
            control={
              <Checkbox
                color={props.isWhite ? 'inherit' : 'primary'}
                className={props.isWhite ? classes.root : undefined}
                checked={tos}
                error={isTosError}
                onChange={() => {
                  setTos(!tos);
                }}
              />
            }
            label={
              <Typography color="textPrimary" display="inline">
                I have read and accept the{' '}
                <a href="/about/terms" target="_blank">
                  <u>terms of service</u>
                </a>{' '}
                and{' '}
                <a href="/about/privacy" target="_blank">
                  <u>privacy policy</u>
                </a>
              </Typography>
            }
          />
          {isTosError && (
            <FormHelperText>
              You need to accept our terms of service and privacy policy to
              continue
            </FormHelperText>
          )}
        </FormControl>
        <FormControlLabel
          control={
            <Checkbox
              color={props.isWhite ? 'inherit' : 'primary'}
              className={props.isWhite ? classes.root : undefined}
              checked={newsletter}
              onChange={() => setNewsletter(!newsletter)}
            />
          }
          label={
            <Typography color="textPrimary" display="inline">
              Optional: Subscribe to our newsletter
            </Typography>
          }
        />
        <div className="pb-2">
          <FormControl variant="standard" required error={isCaptchaError}>
            <ReCAPTCHA
              sitekey={RECAPTCHA_SITE_KEY}
              onChange={token => setCaptcha(token)}
            />
            {isCaptchaError && (
              <FormHelperText>Please complete the captcha</FormHelperText>
            )}
          </FormControl>
        </div>
        {props.isRoundBtn ? (
          <button className="form-control-submit-button" onClick={handleSignUp}>
            SIGN UP FOR FREE
          </button>
        ) : (
          <Button
            size="large"
            variant="contained"
            color={props.isWhite ? 'secondary' : 'primary'}
            onClick={handleSignUp}
          >
            Sign Up
            {signUpRunning && (
              <>
                <CircularProgress className="ml-2" size={24} />
              </>
            )}
          </Button>
        )}
      </FormGroup>
    </>
  );
};

OnboardStart.defaultProps = {
  autoFocus: true,
};

export default OnboardStart;
