import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { GET_SHORT_PROFILE } from '../../helpers/graphql/profile';
import graphQLClient from '../../helpers/graphQLClient';
import validateAccountName from '../../helpers/validateAccountName';
import Link from '@mui/material/Link';

const UsernameInput = props => {
  const {
    onChange,
    isValid,
    setValid,
    showInvalidText,
    textFieldVariant,
    customTextField,
    isError,
  } = props;

  const [value, setValue] = useState(undefined);
  const [timer, setTimer] = useState(undefined);

  const triggerChange = newval => () => {
    if (newval)
      graphQLClient(GET_SHORT_PROFILE, {
        username: newval,
      })
        .then(({ profile }) => {
          if (profile) {
            setValid(false);
            onChange('');
          } else {
            // eslint-disable-next-line no-lonely-if
            if (validateAccountName(newval) == null) {
              onChange(newval);
              setValid(true);
            } else onChange('');
          }
        })
        .catch(() => setValid(false));
  };

  const handleValueChange = newVal => {
    onChange('');
    setValid(undefined);
    setTimer(clearTimeout(timer));

    const smallNewVal = newVal ? newVal.toLowerCase() : undefined;

    setValue(smallNewVal);

    setTimer(setTimeout(triggerChange(smallNewVal), 1000));
  };

  const handleChange = event => {
    handleValueChange(event.target.value);
  };

  useEffect(() => {
    handleValueChange(props.data);
  }, []);

  const UsernameTextField = customTextField || TextField;

  return (
    <>
      <FormControl
        variant="standard"
        fullWidth
        error={(value && validateAccountName(value) !== null) || isError}
      >
        <FormGroup>
          <UsernameTextField
            autoFocus={props.autoFocus}
            error={
              isError
              // &&
              // (validateAccountName(value) !== null || isValid === false)
            }
            fullWidth
            inputProps={{
              maxLength: 255,
            }}
            id="standard-name"
            value={value}
            placeholder={props.placeholder}
            label="Username"
            onChange={handleChange}
            margin="normal"
            variant={textFieldVariant}
          />
          {isError && (
            <>
              {value ? (
                <FormHelperText>
                  {(isValid === false && 'Username already exists') ||
                    validateAccountName(value) ||
                    (isValid === undefined && 'Checking availability...')}
                </FormHelperText>
              ) : (
                <FormHelperText>Please pick a username</FormHelperText>
              )}
            </>
          )}
        </FormGroup>
      </FormControl>
      {showInvalidText && isError && (
        <p>
          The reason why your username is unavailable might be that your
          previously signed up to TravelFeed with a Steem account. The Steem
          integration is deprecated. Please{' '}
          <Link href="/about/contact">contact support</Link> to migrate your
          account to Hive or pick a fresh username to create a new account.
        </p>
      )}
    </>
  );
};

UsernameInput.propTypes = {
  placeholder: PropTypes.string.isRequired,
  data: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default UsernameInput;
